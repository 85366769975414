"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useKeyboard = () => {
    const [keyPressed, setKeyPressed] = (0, react_1.useState)(false);
    const keyDown = (event) => {
        if (event.altKey) {
            setKeyPressed(true);
        }
    };
    const keyUp = (event) => {
        if (event.key === 'Alt') {
            setKeyPressed(false);
        }
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('keydown', keyDown);
        window.addEventListener('keyup', keyUp);
        return () => {
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
        };
    }, []);
    return keyPressed;
};
exports.default = useKeyboard;
