"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeasureBoundingBox = exports.Rectangle = void 0;
class Rectangle {
    constructor(origin, height, width) {
        this.origin = origin;
        this.height = height;
        this.width = width;
    }
}
exports.Rectangle = Rectangle;
class MeasureBoundingBox extends Rectangle {
    /**
     * Checks whether a graphical elements is contained within this measure bounding box. This does not perform
     * an exact bounds check but applies a heuristic with a certain overshoot.
     *
     * @param graphicalElement The graphical element to check for containment within this measure bounding box.
     *
     * @returns whether the `graphicalElements` is contained within this measure bounding box.
     */
    contains(graphicalElement) {
        const leftX = this.origin.x;
        const rightX = this.origin.x + this.width;
        const topY = this.origin.y;
        const bottomY = this.origin.y + this.height;
        const rectangleLeftX = graphicalElement.x;
        const rectangleRightX = graphicalElement.x + graphicalElement.width;
        const rectangleTopY = graphicalElement.y;
        const rectangleBottomY = graphicalElement.y + graphicalElement.height;
        // The overshoot is selected arbitrarily but large enough.
        const maximumOvershoot = 50;
        let overshoot = 0;
        if (leftX > rectangleLeftX) {
            overshoot += leftX - rectangleLeftX;
        }
        if (rightX < rectangleRightX) {
            overshoot += rectangleRightX - rightX;
        }
        if (topY > rectangleTopY) {
            overshoot += topY - rectangleTopY;
        }
        if (bottomY < rectangleBottomY) {
            overshoot += rectangleBottomY - bottomY;
        }
        /*
         * Heuristically include elements that do belong to the measure
         * but are partially outside of the bounding box.
         */
        return overshoot <= maximumOvershoot;
    }
}
exports.MeasureBoundingBox = MeasureBoundingBox;
