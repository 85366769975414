"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSettings = exports.useSections = exports.useSection = exports.useParts = exports.useMusicSheet = void 0;
const react_1 = require("react");
const Settings_1 = require("./Settings/Settings");
const MusicSheetAnalysisContext = (0, react_1.createContext)(undefined);
const useMusicSheet = () => {
    const { musicSheet } = (0, react_1.useContext)(MusicSheetAnalysisContext);
    return musicSheet;
};
exports.useMusicSheet = useMusicSheet;
const useParts = () => {
    const { parts, setParts } = (0, react_1.useContext)(MusicSheetAnalysisContext);
    return [parts, setParts];
};
exports.useParts = useParts;
const useSection = (measureIndex) => {
    const { sections } = (0, react_1.useContext)(MusicSheetAnalysisContext);
    return sections.find(section => section.contains(measureIndex));
};
exports.useSection = useSection;
const useSections = () => {
    const { sections, setSections } = (0, react_1.useContext)(MusicSheetAnalysisContext);
    return [sections, setSections];
};
exports.useSections = useSections;
const useSettings = () => {
    const { settings, setSettings } = (0, react_1.useContext)(MusicSheetAnalysisContext);
    const isRhythmDisplayedAbsolute = settings.displayRhythmAbsolute;
    const isRhythmDisplayedThroughColor = settings.displayChangeInRhythmThrough === Settings_1.RelativeRhythmViewOption.DISPLAY_THROUGH_COLOR;
    const isRhythmDisplayedThroughOpacity = !isRhythmDisplayedThroughColor;
    const rhythmGlyphDisplayMode = settings.rhythmGlyphDisplayMode;
    const showSectionIndicatorOnGlyph = settings.showSectionIndicatorOnGlyph;
    const showTimeSignatureOnGlyph = settings.showTimeSignatureOnGlyph;
    const toggleDisplayColorOfRests = () => setSettings({
        ...settings,
        displayRestsWithColor: !settings.displayRestsWithColor
    });
    const toggleRelativeRhythmViewOption = () => {
        let newRelativeRhythmViewOption;
        if (isRhythmDisplayedThroughColor) {
            newRelativeRhythmViewOption = Settings_1.RelativeRhythmViewOption.DISPLAY_THROUGH_OPACITY;
        }
        else {
            newRelativeRhythmViewOption = Settings_1.RelativeRhythmViewOption.DISPLAY_THROUGH_COLOR;
        }
        setSettings({
            ...settings,
            displayChangeInRhythmThrough: newRelativeRhythmViewOption
        });
    };
    const toggleRhythmGlyphDisplayMode = () => {
        let newRhythmGlyphDisplayMode;
        if (rhythmGlyphDisplayMode === Settings_1.RhythmGlyphDisplayMode.OFFSET_BASED) {
            newRhythmGlyphDisplayMode = Settings_1.RhythmGlyphDisplayMode.HIERARCHY_BASED;
        }
        else {
            newRhythmGlyphDisplayMode = Settings_1.RhythmGlyphDisplayMode.OFFSET_BASED;
        }
        setSettings({ ...settings, rhythmGlyphDisplayMode: newRhythmGlyphDisplayMode });
    };
    const toggleRhythmViewMode = () => {
        setSettings({
            ...settings,
            displayRhythmAbsolute: !settings.displayRhythmAbsolute
        });
    };
    const toggleSectionIndicatorOnGlyph = () => setSettings({
        ...settings,
        showSectionIndicatorOnGlyph: !settings.showSectionIndicatorOnGlyph
    });
    const toggleTimeSignatureOnGlyph = () => setSettings({
        ...settings,
        showTimeSignatureOnGlyph: !settings.showTimeSignatureOnGlyph
    });
    return {
        absoluteColorMap: settings.absoluteColorMap,
        displayChangeInRhythmThrough: settings.displayChangeInRhythmThrough,
        displayRestsWithColor: settings.displayRestsWithColor,
        isRhythmDisplayedAbsolute,
        isRhythmDisplayedRelative: !isRhythmDisplayedAbsolute,
        isRhythmDisplayedThroughColor,
        isRhythmDisplayedThroughOpacity,
        relativeColorMap: settings.relativeColorMap,
        rhythmGlyphDisplayMode,
        showSectionIndicatorOnGlyph,
        showTimeSignatureOnGlyph,
        toggleDisplayColorOfRests,
        toggleRelativeRhythmViewOption,
        toggleRhythmGlyphDisplayMode,
        toggleRhythmViewMode,
        toggleSectionIndicatorOnGlyph,
        toggleTimeSignatureOnGlyph
    };
};
exports.useSettings = useSettings;
exports.default = MusicSheetAnalysisContext;
