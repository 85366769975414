"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class MusicSheet {
    /**
     * Represent a music sheet.
     *
     * @param id The ID of the music sheet in the database.
     * @param musicXML The music sheet in [MusicXML](https://www.musicxml.com/).
     * @param numberOfMeasures The number of measures that this music sheet comprises.
     * @param title The title of the music sheet.
     * @param composer The composer of the music sheet.
     * @param category The category of the music sheet.
     */
    constructor(id, musicXML, numberOfMeasures, title, composer, category) {
        this.id = id;
        this.musicXML = musicXML;
        this.numberOfMeasures = numberOfMeasures;
        this.title = title;
        this.composer = composer;
        this.category = category;
    }
}
exports.default = MusicSheet;
