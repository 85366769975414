"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const Note_1 = require("../MusicSheet/Note");
/**
 * A custom hook to set listeners on rhythmic occurrences of a music sheet
 * to highlight their corresponding [[Arc]] in the [[RhythmGlyph]].
 *
 * @param graphicalRhythmicOccurrences The [[VexFlowGraphicalNote]]s to attach listeners to.
 * @param offset The offset of notes to attach listeners to.
 * @param onRests Indicates whether the listeners should only be attached to rests.
 */
const useHighlightedArc = (graphicalRhythmicOccurrences, offset, onRests) => {
    const [isHighlighted, setIsHighlighted] = (0, react_1.useState)(false);
    const vexFlowNotesToInteractWith = graphicalRhythmicOccurrences.filter((vexFlowNote) => vexFlowNote.parentVoiceEntry.parentStaffEntry.relInMeasureTimestamp.RealValue *
        Note_1.Duration.WHOLE ===
        offset &&
        (onRests === undefined || vexFlowNote.sourceNote.isRest() === onRests));
    const turnHighlightOn = () => setIsHighlighted(true);
    const turnHighlightOff = () => setIsHighlighted(false);
    (0, react_1.useEffect)(() => {
        vexFlowNotesToInteractWith.forEach((vexFlowNote) => {
            const element = vexFlowNote.getSVGGElement();
            element.addEventListener('mouseenter', turnHighlightOn);
            element.addEventListener('mouseleave', turnHighlightOff);
        });
        return () => {
            vexFlowNotesToInteractWith.forEach((vexFlowNote) => {
                const element = vexFlowNote.getSVGGElement();
                element.removeEventListener('mouseenter', turnHighlightOn);
                element.removeEventListener('mouseleave', turnHighlightOff);
            });
        };
    }, []);
    return isHighlighted;
};
exports.default = useHighlightedArc;
