"use strict";
/**
 * Refer to [the issue on GitHub](https://github.com/opensheetmusicdisplay/opensheetmusicdisplay/issues/504)
 * for more information about conversion between pixels and units in OSMD.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertUnitsToPixels = exports.convertPixelsToUnits = void 0;
const openSheetMusicDisplayUnitInPixels = 10;
const convertPixelsToUnits = (pixelDistance) => pixelDistance / openSheetMusicDisplayUnitInPixels;
exports.convertPixelsToUnits = convertPixelsToUnits;
const convertUnitsToPixels = (unitDistance) => unitDistance * openSheetMusicDisplayUnitInPixels;
exports.convertUnitsToPixels = convertUnitsToPixels;
class Point {
    constructor(x = 0.0, y = 0.0) {
        this.x = x;
        this.y = y;
    }
}
exports.default = Point;
