"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSectionBoundary = void 0;
const react_1 = require("react");
const SectionDialogContext = (0, react_1.createContext)(undefined);
const useSectionBoundary = () => {
    const { firstMeasureIndex, setFirstMeasureIndex, lastMeasureIndex, setLastMeasureIndex } = (0, react_1.useContext)(SectionDialogContext);
    return (measureIndex) => {
        if (firstMeasureIndex === null) {
            setFirstMeasureIndex(measureIndex);
        }
        else if (lastMeasureIndex === null) {
            // The last measure might be set first in which case the indices need to be switched.
            if (measureIndex < firstMeasureIndex) {
                setLastMeasureIndex(firstMeasureIndex);
                setFirstMeasureIndex(measureIndex);
            }
            else {
                setLastMeasureIndex(measureIndex);
            }
        }
        else {
            if (measureIndex < firstMeasureIndex)
                setFirstMeasureIndex(measureIndex);
            if (measureIndex > lastMeasureIndex)
                setLastMeasureIndex(measureIndex);
        }
    };
};
exports.useSectionBoundary = useSectionBoundary;
exports.default = SectionDialogContext;
