"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const underscore_1 = require("underscore");
const Note_1 = require("../MusicSheet/Note");
class RhythmicFingerprint {
    constructor(rhythmicFingerprintAsJSON, measureIndex = 0) {
        this.measureIndex = measureIndex;
        // tslint:disable-next-line: no-shadowed-variable
        const { byDuration, byVoice, duration, repetition, timeSignature } = rhythmicFingerprintAsJSON;
        this.duration = duration;
        this.durations = byDuration;
        this.repetition = repetition;
        this.voices = byVoice;
        this.timeSignature = timeSignature;
    }
    get RhythmicOccurrencesAsRings() {
        return this.voices;
    }
    /**
     * Checks whether all `rhythmicOccurrences` are repetitions from the preceding [[RhythmicFingerprint]].
     *
     * @param rhythmicOccurrences The [[RhythmicOccurrence]]s to check.
     *
     * @returns `true` iff all `rhythmicOccurrences` are repetitions from the preceding [[RhythmicFingerprint]],
     *          `false` otherwise.
     */
    static areRhythmicOccurrencesRepetitions(rhythmicOccurrences) {
        return rhythmicOccurrences.every(rhythmicOccurrence => rhythmicOccurrence.presentInPreviousRhythmicFingerprint);
    }
    /**
     * Checks whether all `rhythmicOccurrences` are rests.
     *
     * @param rhythmicOccurrences The [[RhythmicOccurrence]]s to check.
     *
     * @returns `true` iff all `rhythmicOccurrences` are rests, `false` otherwise.
     */
    static areRhythmicOccurrencesRests(rhythmicOccurrences) {
        return rhythmicOccurrences.every(rhythmicOccurrence => rhythmicOccurrence.isRest);
    }
    /**
     * Creates an empty [[RhythmicFingerprint]].
     *
     * @returns an empty [[RhythmicFingerprint]].
     */
    static createEmptyRhythmicFingerprint() {
        const rhythmicFingerprintAsJSON = {
            byDuration: {
                [Note_1.DurationDesignation.WHOLE]: [],
                [Note_1.DurationDesignation.HALF]: [],
                [Note_1.DurationDesignation.QUARTER]: [],
                [Note_1.DurationDesignation.EIGHTH]: [],
                [Note_1.DurationDesignation.SIXTEENTH]: [],
                [Note_1.DurationDesignation.THIRTY_SECOND]: []
            },
            byVoice: [],
            repetition: 0,
            timeSignature: {
                denominator: 0,
                numerator: 0
            }
        };
        return new RhythmicFingerprint(rhythmicFingerprintAsJSON);
    }
    /**
     * Gets the rhythmic occurrences with a specified duration, within a specific arc (i.e. range of note onsets),
     * and grouped by their offset. This does not include rhythmic occurrences that appear at the start of the arc.
     *
     * @param duration The [[Duration]] to look for a rhythmic occurrence in.
     * @param arcIndex The arc index to look for a rhythmic occurrence in.
     *
     * @returns The rhythmic occurrences for the specified duration, within the specified arc, and grouped by their
     *          offset. This does not include rhythmic occurrences that appear at the start of the arc.
     */
    getGroupedRhythmicOccurrencesWithin(duration, arcIndex) {
        const filter = (rhythmicOccurrence, rhythmicOccurrenceStart, rhythmicOccurrenceEnd) => rhythmicOccurrence.offset > rhythmicOccurrenceStart &&
            rhythmicOccurrence.offset < rhythmicOccurrenceEnd;
        return (0, underscore_1.groupBy)(this.getRhythmicOccurrencesForArc(duration, arcIndex, filter), rhythmicOccurrence => rhythmicOccurrence.offset);
    }
    /**
     * Gets the rhythmic occurrences with a specified duration at the beginning of a specific arc (i.e. range of
     * note onsets).
     *
     * @param duration The [[Duration]] to look for a rhythmic occurrence in.
     * @param arcIndex The arc index to look for a rhythmic occurrence in.
     *
     * @returns The rhythmic occurrences for the specified duration at the beginning of the specified arc.
     */
    getRhythmicOccurrencesExactlyAt(duration, arcIndex) {
        const filter = (rhythmicOccurrence, rhythmicOccurrenceStart, _) => rhythmicOccurrence.offset === rhythmicOccurrenceStart;
        return this.getRhythmicOccurrencesForArc(duration, arcIndex, filter);
    }
    /**
     * Checks whether this [[RhythmicFingerprint]] is empty, i.e., contains no rhythmic occurrences.
     *
     * @returns `true` iff this [[RhythmicFingerprint]] contains no rhythmic occurrences and `false`, otherwise.
     */
    isEmpty() {
        for (const duration of Object.values(this.durations)) {
            if (duration.length > 0)
                return false;
        }
        return this.voices.length <= 0;
    }
    /**
     * Checks whether this rhythmic fingerprint is a subset of `rhythmicFingerprint`.
     *
     * This subset check ignores the multiplicity of rhythmic occurrences. That is, a rhythmic fingerprint can be equal
     * to another rhythmic fingerprint even if there is a rhythmic occurrence at the same offset with the same duration
     * but of another multiplicity.
     *
     * @param rhythmicFingerprint Another rhythmic fingerprint.
     *
     * @returns a flag indicating the subset relation between this rhythmic fingerprint and `rhythmicFingerprint`.
     */
    isSubsetOf(rhythmicFingerprint) {
        return Object.entries(this.durations).every(([duration, rhythmicOccurrences]) => {
            var _a;
            const otherRhythmicOccurrences = (_a = rhythmicFingerprint.durations[duration]) !== null && _a !== void 0 ? _a : [];
            if (rhythmicOccurrences.length !== otherRhythmicOccurrences.length)
                return false;
            return rhythmicOccurrences.every(rhythmicOccurrence => otherRhythmicOccurrences.some(otherRhythmicOccurrence => rhythmicOccurrence.offset === otherRhythmicOccurrence.offset &&
                rhythmicOccurrence.duration === otherRhythmicOccurrence.duration &&
                rhythmicOccurrence.isRest === otherRhythmicOccurrence.isRest &&
                rhythmicOccurrence.isDotted === otherRhythmicOccurrence.isDotted));
        });
    }
    /**
     * Gets the rhythmic occurrences with a specified duration within a specific arc (i.e. range of note onsets).
     * Afterwards, a `filter` is applied to these rhythmic occurrences.
     *
     * @param duration The [[Duration]] to look for a rhythmic occurrence in.
     * @param arcIndex The arc index to look for a rhythmic occurrence in.
     * @param filter The filter to apply to the rhythmic occurrences.
     *
     * @returns The `filter`ed rhythmic occurrences for the specified duration within the specified arc.
     */
    getRhythmicOccurrencesForArc(duration, arcIndex, filter) {
        const rhythmicOccurrences = this.getRhythmicOccurrences(duration);
        if (!rhythmicOccurrences || rhythmicOccurrences.length === 0) {
            return [];
        }
        const rhythmicOccurrenceStart = duration * arcIndex;
        const rhythmicOccurrenceEnd = duration * (arcIndex + 1);
        return rhythmicOccurrences.filter((rhythmicOccurrence) => filter(rhythmicOccurrence, rhythmicOccurrenceStart, rhythmicOccurrenceEnd));
    }
    /**
     * Gets the rhythmic occurrences for a duration.
     *
     * @param duration The duration to get the rhythmic occurrences for.
     *
     * @returns the rhythmic occurrences for the specified duration.
     */
    getRhythmicOccurrences(duration) {
        var _a;
        const durationDesignator = Note_1.DurationDesignation[Note_1.Duration[duration]];
        return (_a = this.durations[durationDesignator]) !== null && _a !== void 0 ? _a : [];
    }
}
exports.default = RhythmicFingerprint;
