"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUndottedDuration = exports.durations = exports.Duration = exports.DurationDesignation = void 0;
/**
 * Represent the designations of the possible durations.
 */
var DurationDesignation;
(function (DurationDesignation) {
    DurationDesignation["THIRTY_SECOND"] = "32nd";
    DurationDesignation["SIXTEENTH"] = "16th";
    DurationDesignation["EIGHTH"] = "eighth";
    DurationDesignation["QUARTER"] = "quarter";
    DurationDesignation["HALF"] = "half";
    DurationDesignation["WHOLE"] = "whole";
})(DurationDesignation = exports.DurationDesignation || (exports.DurationDesignation = {}));
/**
 * Represent the absolute durations (measured in multiples of a quarter note) of the possible durations.
 */
var Duration;
(function (Duration) {
    Duration[Duration["THIRTY_SECOND"] = 0.125] = "THIRTY_SECOND";
    Duration[Duration["SIXTEENTH"] = 0.25] = "SIXTEENTH";
    Duration[Duration["EIGHTH"] = 0.5] = "EIGHTH";
    Duration[Duration["QUARTER"] = 1] = "QUARTER";
    Duration[Duration["HALF"] = 2] = "HALF";
    Duration[Duration["WHOLE"] = 4] = "WHOLE";
})(Duration = exports.Duration || (exports.Duration = {}));
/**
 * An `enum` with `number`ish values transpile into a bidirectional translation.
 * That is, `Object.values()` also returns the `enum`s keys. To circumvent this,
 * members with the value type can be `filter`ed from the output of `Object.values()`.
 *
 * Refer to [this GitHub comment](https://github.com/Microsoft/TypeScript/issues/17198#issuecomment-315400819) and
 * observe [[[NoteDuration]]s transpilation](https://cutt.ly/Ne7mMtY) for in-depth details.
 */
exports.durations = Object.values(Duration).filter((duration) => typeof duration === 'number');
/**
 * Gets the base duration of a dotted duration.
 *
 * @param duration The dotted duration.
 *
 * @returns `duration` if already undotted, the base duration if dotted, or `undefined`.
 */
const getUndottedDuration = (duration) => {
    const noteDurationIndex = exports.durations.indexOf(duration);
    if (noteDurationIndex >= 0)
        return duration;
    const durationsInDescendingOrder = [...exports.durations].reverse();
    for (const shorterDuration of durationsInDescendingOrder) {
        if (shorterDuration < duration)
            return shorterDuration;
    }
    return undefined;
};
exports.getUndottedDuration = getUndottedDuration;
