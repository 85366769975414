"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.database = void 0;
const RestClient_1 = require("typed-rest-client/RestClient");
const dockerContainer = 'visual-rhythm-analysis-backend';
const isRunningInCI = Number(process.env.CI) === 1;
const isRunningInDocker = Number(process.env.DOCKER) === 1;
let host;
if (isRunningInCI) {
    host = `${window.location.protocol}//api.${window.location.hostname}`;
}
else if (isRunningInCI) {
    host = `http://${dockerContainer}:5000`;
}
else {
    host = 'http://localhost:5000';
}
exports.database = new RestClient_1.RestClient('frontend', host);
exports.default = host;
