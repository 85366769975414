"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Section {
    constructor(name, color, measureIndexRange, isSuggested = false) {
        this.id = (0, uuid_1.v4)();
        this.color = color;
        this.isSuggested = isSuggested;
        this.name = name;
        this.firstMeasureIndex = measureIndexRange[0];
        this.lastMeasureIndex = measureIndexRange[1];
    }
    get Color() {
        return this.color;
    }
    set Color(color) {
        this.color = color;
    }
    get EndIndex() {
        return this.lastMeasureIndex;
    }
    set EndIndex(index) {
        this.lastMeasureIndex = index;
    }
    get IsSuggested() {
        return this.isSuggested;
    }
    set IsSuggested(isSuggested) {
        this.isSuggested = isSuggested;
    }
    get Name() {
        return this.name;
    }
    set Name(name) {
        this.name = name;
    }
    get Part() {
        return this.part;
    }
    set Part(part) {
        this.part = part;
    }
    get StartIndex() {
        return this.firstMeasureIndex;
    }
    set StartIndex(index) {
        this.firstMeasureIndex = index;
    }
    addTo(part) {
        this.part = part;
    }
    contains(measureIndex) {
        return measureIndex >= this.firstMeasureIndex && measureIndex <= this.lastMeasureIndex;
    }
    encloses(measureIndexRange) {
        return (measureIndexRange[0] >= this.firstMeasureIndex &&
            measureIndexRange[1] <= this.lastMeasureIndex);
    }
}
exports.default = Section;
