"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
/**
 * A wrapper for asynchronous React Effects.
 *
 * @param effect The function to execute as an effect.
 * @param deps The dependencies to check for the execution of the `effect`.
 */
const useAsynchronousEffect = (effect, deps) => {
    (0, react_1.useEffect)(() => {
        (async () => await effect())();
    }, deps);
};
exports.default = useAsynchronousEffect;
