"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const uuid_1 = require("uuid");
class Part {
    constructor(name) {
        this.sections = [];
        this.id = (0, uuid_1.v4)();
        this.name = name;
    }
    get ID() {
        return this.id;
    }
    get MeasureRanges() {
        return this.sections.map(section => [section.StartIndex, section.EndIndex]);
    }
    get Name() {
        return this.name;
    }
    get SectionCount() {
        return this.sections.length;
    }
    add(section) {
        this.sections.push(section);
    }
    remove(section) {
        const sectionIndex = this.sections.indexOf(section);
        this.sections.splice(sectionIndex, 1);
    }
}
exports.default = Part;
